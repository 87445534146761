
import {defineComponent, onMounted, ref } from 'vue';
import {useCreatingForm} from '@/core/hooks/use-creating-form';
import {IDanhMucToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {swalNotification} from '@/core/helpers/utils';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

import DanhMucToaXeService from '@/core/services/DanhMucToaXe.service';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';
import DivRow from '../../../../layout/_shared/DivRow.vue';

export default defineComponent({
	name: 'danh-muc-toa-xe-form',

	components: { ButtonCustom, DivRow, PageRowLayout },

	setup() {
		const { isCreating, id, loading, formRef } = useCreatingForm();
		const { goBack, replace } = useRouterCustom();
		const { ButtonTypeColors, ButtonsType } = useButtonCustom();

		const danhMucToaXe = ref<IDanhMucToaXeResponseBase | null>(null);
		const danhMucToaXeChaItems = ref<IDanhMucToaXeResponseBase[]>([]);
		const danhMucToaXeForm = ref({
			ten_danh_muc: '',
			mo_ta: '',
			id_cha: 0,
		})

		onMounted(async () => {
			setCurrentPageTitle("Danh mục toa xe");
			if (!isCreating.value) {
				const {data: {data: {ten_danh_muc, id_cha, mo_ta}}} = await DanhMucToaXeService.get(id.value);

				danhMucToaXeForm.value.ten_danh_muc = ten_danh_muc;
				danhMucToaXeForm.value.mo_ta = mo_ta;
				danhMucToaXeForm.value.id_cha = id_cha;
			}


			const { data: { data: {data: res} } } = await DanhMucToaXeService.list();
			danhMucToaXeChaItems.value = res.filter((item) => item.id !== id.value);
		});

		return {
			ButtonTypeColors, ButtonsType,
			danhMucToaXeId: id,
			loading,
			danhMucToaXeForm,
			isCreating,
			formRef,
			danhMucToaXe,
			goBack,
			replace,
			danhMucToaXeChaItems,
		}
	},

	data() {
		return {
			rules: {
				ten_danh_muc: [
					{
						required: true,
						message: "Mời bạn nhập tên danh mục toa xe",
						trigger: "blur",
					}
				],
				id_cha: [
					{
						required: false,
					},
				],
			}
		};
	},

	methods: {
		clear() {
			this.danhMucToaXeForm.ten_danh_muc = '';
			this.danhMucToaXeForm.mo_ta = '';
			this.danhMucToaXeForm.id_cha = 0;
		},

		submit() {
			if (!this.formRef) {
				return;
			}

			this.formRef.validate(async (valid) => {
				if (valid) {
					this.loading = false;

					try {
						if (this.isCreating) {
							const { data: { data: { id } } } = await DanhMucToaXeService.create(
								this.danhMucToaXeForm.ten_danh_muc,
								this.danhMucToaXeForm.mo_ta,
								this.danhMucToaXeForm.id_cha,
							)
							this.danhMucToaXeId = id;
						} else {
							await DanhMucToaXeService.update(
								this.danhMucToaXeId,
								this.danhMucToaXeForm.ten_danh_muc,
								this.danhMucToaXeForm.mo_ta,
								this.danhMucToaXeForm.id_cha,
							)
						}

						await swalNotification(
							`${this.isCreating ? 'Tạo mới' : 'Chỉnh sửa'} danh mục toa xe thành công`
						)

						await this.replace(`/quan-ly/danh-muc-toa-xe/${this.danhMucToaXeId}/chi-tiet`)
					} catch {
						await swalNotification(
							'Xin lỗi, có vẻ có lỗi xảy ra',
							'error'
						);
					}
				} else {
					return false;
				}
			})
		}
	}
})
